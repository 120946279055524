import React from 'react'
import Textarea from 'react-textarea-autocomplete'

function App () {
  return (
    <div className='App'>
      <header className='App-header'>
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className='App-link'
          href='https://reactjs.org'
          target='_blank'
          rel='noopener noreferrer'
        >
          Learn React
        </a>
        <div style={{ width: 300, margin: '0 auto' }}>

          <Textarea
            list={[
              'manzana',
              'mango',
              'maracuya',
              'peluche'
            ]}
            char='#'
          />
        </div>
      </header>
    </div>
  )
}

export default App
